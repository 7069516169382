@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');
@import url('assets/styles/variables.css');

* {
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iva-card-border-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D1D2D3FF' stroke-width='2' stroke-dasharray='7%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

/* Estilos del safe area */
.ion-padding {
  padding-top: var(--ion-safe-area-top, 0);
  padding-right: var(--ion-safe-area-right, 0);
  padding-bottom: var(--ion-safe-area-bottom, 0);
  padding-left: var(--ion-safe-area-left, 0);
}

.safe-area-top {
  padding-top: var(--ion-safe-area-top, 0);
}

.safe-area-right {
  padding-right: var(--ion-safe-area-right, 0);
}

.safe-area-bottom {
  padding-bottom: var(--ion-safe-area-bottom, 0);
}

.safe-area-left {
  padding-left: var(--ion-safe-area-left, 0);
}

/* Estilos aplicados a los textos de la aplicación */
.text-small {
  font-size: 0.75rem;
}

.text-mid {
  font-size: 0.85rem;
}

.text-large {
  font-size: 0.95rem;
}

/* Estilo aplicado a los textarea para que su altura crezca dinámicamente 
según el texto que contengan */
textarea {
  field-sizing: content;
}

/* Estilo aplicado a las tarjetas de información que se muestran
en el perfil del usuario */
.account-information-card {
  transition: box-shadow .3s;
  box-shadow: 0px 1px 3px 1px rgba(116, 113, 113, 0.15), 0px 1px 2px 0px rgba(77, 75, 75, 0.3);
}

.logo-card {
  transition: box-shadow .3s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

/* Estilo aplicado a los Accordions que se muestran en los filtros de búsqueda de los dashboards
de Góndola y Frío, Pedidos y Vendedores */
.accordion-search-filter {
  box-shadow: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#main-section::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#main-section {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}