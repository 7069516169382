:root {
	--ion-toolbar-background: #F7F7F7;

	--ion-color-primary: #183555;
	--ion-color-primary-rgb: 0,51,102;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #002d5a;
	--ion-color-primary-tint: #1a4775;

	--ion-color-secondary: #E40909;
	--ion-color-secondary-rgb: 228,9,9;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #c90808;
	--ion-color-secondary-tint: #e72222;

	--ion-color-tertiary: #EAEAEA;
	--ion-color-tertiary-rgb: 234,234,234;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #cecece;
	--ion-color-tertiary-tint: #ececec;

	--ion-color-success: #39C408;
	--ion-color-success-rgb: 57,196,8;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #32ac07;
	--ion-color-success-tint: #4dca21;

	--ion-color-warning: #F7C02B;
	--ion-color-warning-rgb: 247,192,43;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #d9a926;
	--ion-color-warning-tint: #f8c640;

    --ion-color-danger: #B3261E;
    --ion-color-danger-rgb: 179, 38, 30;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #9e211a;
    --ion-color-danger-tint: #FDEDED;

	--ion-color-light: #F5F5F5;
	--ion-color-light-rgb: 244,245,245;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8d8;
	--ion-color-light-tint: #f5f6f6;

	--ion-color-medium: #828282;
	--ion-color-medium-rgb: 123,129,137;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #6c7279;
	--ion-color-medium-tint: #888e95;

	--ion-color-dark: #000;
	--ion-color-dark-rgb: 0,0,0;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #000000;
	--ion-color-dark-tint: #1a1a1a;

	--ion-color-basic-card: #112A56;
	--ion-color-basic-card-rgb: 29,70,143;
	--ion-color-basic-card-contrast: #ffffff;
	--ion-color-basic-card-contrast-rgb: 255,255,255;
	--ion-color-basic-card-shade: #1a3e7e;
	--ion-color-basic-card-tint: #34599a;

	--ion-color-gold: #c1910d;
	--ion-color-gold-rgb: 238,198,88;
	--ion-color-gold-contrast: #000000;
	--ion-color-gold-contrast-rgb: 0,0,0;
	--ion-color-gold-shade: #d1ae4d;
	--ion-color-gold-tint: #f0cc69;

	--ion-color-platinum: #687072;
	--ion-color-platinum-rgb: 163,166,168;
	--ion-color-platinum-contrast: #000000;
	--ion-color-platinum-contrast-rgb: 0,0,0;
	--ion-color-platinum-shade: #8f9294;
	--ion-color-platinum-tint: #acafb1;

	--ion-color-progress-bar-orange: #FFB26C;
	--ion-color-progress-bar-orange-rgb: 255,178,108;
	--ion-color-progress-bar-orange-contrast: #000000;
	--ion-color-progress-bar-orange-contrast-rgb: 0,0,0;
	--ion-color-progress-bar-orange-shade: #e09d5f;
	--ion-color-progress-bar-orange-tint: #ffba7b;

	--ion-color-progress-bar-red: #E16363;
	--ion-color-progress-bar-red-rgb: 225,99,99;
	--ion-color-progress-bar-red-contrast: #000000;
	--ion-color-progress-bar-red-contrast-rgb: 0,0,0;
	--ion-color-progress-bar-red-shade: #c65757;
	--ion-color-progress-bar-red-tint: #e47373;

	--ion-color-progress-bar-green: #8CCD81;
	--ion-color-progress-bar-green-rgb: 140,205,129;
	--ion-color-progress-bar-green-contrast: #000000;
	--ion-color-progress-bar-green-contrast-rgb: 0,0,0;
	--ion-color-progress-bar-green-shade: #7bb472;
	--ion-color-progress-bar-green-tint: #98d28e;

	--ion-color-progress-bar-yellow: #FFD74B;
	--ion-color-progress-bar-yellow-rgb: 255,215,75;
	--ion-color-progress-bar-yellow-contrast: #000000;
	--ion-color-progress-bar-yellow-contrast-rgb: 0,0,0;
	--ion-color-progress-bar-yellow-shade: #e0bd42;
	--ion-color-progress-bar-yellow-tint: #ffdb5d;

	--ion-color-progress-bar-gray: #D9D9D9;
	--ion-color-progress-bar-gray-rgb: 217,217,217;
	--ion-color-progress-bar-gray-contrast: #000000;
	--ion-color-progress-bar-gray-contrast-rgb: 0,0,0;
	--ion-color-progress-bar-gray-shade: #bfbfbf;
	--ion-color-progress-bar-gray-tint: #dddddd;

	--ion-color-dynamic-dashboard-lightblue: #D2E1FF;
	--ion-color-dynamic-dashboard-lightblue-rgb: 210,225,255;
	--ion-color-dynamic-dashboard-lightblue-contrast: #000000;
	--ion-color-dynamic-dashboard-lightblue-contrast-rgb: 0,0,0;
	--ion-color-dynamic-dashboard-lightblue-shade: #b9c6e0;
	--ion-color-dynamic-dashboard-lightblue-tint: #d7e4ff;

	--ion-color-dynamic-dashboard-lightgreen: #D8FFD2;
	--ion-color-dynamic-dashboard-lightgreen-rgb: 216,255,210;
	--ion-color-dynamic-dashboard-lightgreen-contrast: #000000;
	--ion-color-dynamic-dashboard-lightgreen-contrast-rgb: 0,0,0;
	--ion-color-dynamic-dashboard-lightgreen-shade: #bee0b9;
	--ion-color-dynamic-dashboard-lightgreen-tint: #dcffd7;

	--ion-color-dynamic-dashboard-salmon: #FFDBBA;
	--ion-color-dynamic-dashboard-salmon-rgb: 255,219,186;
	--ion-color-dynamic-dashboard-salmon-contrast: #000000;
	--ion-color-dynamic-dashboard-salmon-contrast-rgb: 0,0,0;
	--ion-color-dynamic-dashboard-salmon-shade: #e0c1a4;
	--ion-color-dynamic-dashboard-salmon-tint: #ffdfc1;
}

body.dark {
    --ion-background-color: #02222C;
    --ion-toolbar-background: #01161c;

	--ion-color-dark: #EAEAEA;
	--ion-color-dark-rgb: 234,234,234;
	--ion-color-dark-contrast: #000000;
	--ion-color-dark-contrast-rgb: 0,0,0;
	--ion-color-dark-shade: #cecece;
	--ion-color-dark-tint: #ececec;
}

.ion-color-basic-card {
	--ion-color-base: var(--ion-color-basic-card);
	--ion-color-base-rgb: var(--ion-color-basic-card-rgb);
	--ion-color-contrast: var(--ion-color-basic-card-contrast);
	--ion-color-contrast-rgb: var(--ion-color-basic-card-contrast-rgb);
	--ion-color-shade: var(--ion-color-basic-card-shade);
	--ion-color-tint: var(--ion-color-basic-card-tint);
}

.ion-color-gold {
	--ion-color-base: var(--ion-color-gold);
	--ion-color-base-rgb: var(--ion-color-gold-rgb);
	--ion-color-contrast: var(--ion-color-gold-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
	--ion-color-shade: var(--ion-color-gold-shade);
	--ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-platinum {
	--ion-color-base: var(--ion-color-platinum);
	--ion-color-base-rgb: var(--ion-color-platinum-rgb);
	--ion-color-contrast: var(--ion-color-platinum-contrast);
	--ion-color-contrast-rgb: var(--ion-color-platinum-contrast-rgb);
	--ion-color-shade: var(--ion-color-platinum-shade);
	--ion-color-tint: var(--ion-color-platinum-tint);
}

.ion-color-progress-bar-red {
	--ion-color-base: var(--ion-color-progress-bar-red);
	--ion-color-base-rgb: var(--ion-color-progress-bar-red-rgb);
	--ion-color-contrast: var(--ion-color-progress-bar-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-progress-bar-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-progress-bar-red-shade);
	--ion-color-tint: var(--ion-color-progress-bar-red-tint);
}

.ion-color-progress-bar-yellow {
	--ion-color-base: var(--ion-color-progress-bar-yellow);
	--ion-color-base-rgb: var(--ion-color-progress-bar-yellow-rgb);
	--ion-color-contrast: var(--ion-color-progress-bar-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-progress-bar-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-progress-bar-yellow-shade);
	--ion-color-tint: var(--ion-color-progress-bar-yellow-tint);
}

.ion-color-progress-bar-orange {
	--ion-color-base: var(--ion-color-progress-bar-orange);
	--ion-color-base-rgb: var(--ion-color-progress-bar-orange-rgb);
	--ion-color-contrast: var(--ion-color-progress-bar-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-progress-bar-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-progress-bar-orange-shade);
	--ion-color-tint: var(--ion-color-progress-bar-orange-tint);
}

.ion-color-progress-bar-green {
	--ion-color-base: var(--ion-color-progress-bar-green);
	--ion-color-base-rgb: var(--ion-color-progress-bar-green-rgb);
	--ion-color-contrast: var(--ion-color-progress-bar-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-progress-bar-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-progress-bar-green-shade);
	--ion-color-tint: var(--ion-color-progress-bar-green-tint);
}

.ion-color-progress-bar-gray {
	--ion-color-base: var(--ion-color-progress-bar-gray);
	--ion-color-base-rgb: var(--ion-color-progress-bar-gray-rgb);
	--ion-color-contrast: var(--ion-color-progress-bar-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-progress-bar-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-progress-bar-gray-shade);
	--ion-color-tint: var(--ion-color-progress-bar-gray-tint);
}

.ion-color-dynamic-dashboard-lightblue {
	--ion-color-base: var(--ion-color-dynamic-dashboard-lightblue);
	--ion-color-base-rgb: var(--ion-color-dynamic-dashboard-lightblue-rgb);
	--ion-color-contrast: var(--ion-color-dynamic-dashboard-lightblue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dynamic-dashboard-lightblue-contrast-rgb);
	--ion-color-shade: var(--ion-color-dynamic-dashboard-lightblue-shade);
	--ion-color-tint: var(--ion-color-dynamic-dashboard-lightblue-tint);
}

.ion-color-dynamic-dashboard-lightgreen {
	--ion-color-base: var(--ion-color-dynamic-dashboard-lightgreen);
	--ion-color-base-rgb: var(--ion-color-dynamic-dashboard-lightgreen-rgb);
	--ion-color-contrast: var(--ion-color-dynamic-dashboard-lightgreen-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dynamic-dashboard-lightgreen-contrast-rgb);
	--ion-color-shade: var(--ion-color-dynamic-dashboard-lightgreen-shade);
	--ion-color-tint: var(--ion-color-dynamic-dashboard-lightgreen-tint);
}

.ion-color-dynamic-dashboard-salmon {
	--ion-color-base: var(--ion-color-dynamic-dashboard-salmon);
	--ion-color-base-rgb: var(--ion-color-dynamic-dashboard-salmon-rgb);
	--ion-color-contrast: var(--ion-color-dynamic-dashboard-salmon-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dynamic-dashboard-salmon-contrast-rgb);
	--ion-color-shade: var(--ion-color-dynamic-dashboard-salmon-shade);
	--ion-color-tint: var(--ion-color-dynamic-dashboard-salmon-tint);
}